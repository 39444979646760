<template>
  <!-- 确认入职 -->
  <div class="confirmEntry">
    <van-nav-bar
      title="确认入职"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-field
      label="入职日期"
      :value="entrydate"
      required
      placeholder="请选择"
      @click="selEntryDate"
      right-icon="arrow"
      readonly
    ></van-field>
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="selTime"
        @cancel="cancelSel"
      />
    </van-popup>
    <van-cell center title="手动调整工号">
      <template #right-icon>
        <van-switch v-model="ischange_empcode" size="24" />
      </template>
    </van-cell>
    <van-cell center title="自动获取工号">
      <template #right-icon>
        <van-switch v-model="isautocode" size="24" />
      </template>
    </van-cell>
    <van-field
      v-model="empcode"
      class="name"
      :disabled="!ischange_empcode"
      label="工号"
      placeholder="请输入"
    />
    <van-button type="info" :disabled="!canSave" @click="confirm">确认入职</van-button>
  </div>
</template>

<script>
import { Toast } from "vant";
import { confirmEntry } from "@api/wxzp.js";
export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      autoid: this.$route.query.autoid, // 入职人员ID
      showDate: false,
      entrydate: "",
      minDate: new Date(),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      empcode: "",
      canInput: false,
      ischange_empcode: false, // 是否调整工号，传入 1 或 0
      isautocode: true, // 是否自动获取工号，传入 1 或 0
      dept: "", //部门
      gw: "", //岗位
      workLeve: "", //职级
      showWorkType: false,
      workType: "", //工作性质
      empStatus: "", //员工状态
      name: "",
      columns: ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华", "衢州"],
      popType: "", //记录每次点击弹出下拉弹出窗是哪个
    };
  },
  watch: {
    isautocode: {
      handler(n) {
        if (n) {
          this.ischange_empcode = false;
          this.empcode = "";
        } else {
          this.ischange_empcode = true;
        }
      },
    },
    ischange_empcode:{
      handler(n) {
        if (n) {
          this.isautocode = false;
        } else {
          this.isautocode = true;
          this.empcode = "";
        }
      },
    }
  },
  computed: {
    canSave() {
      return (
        this.entrydate != "" && (this.isautocode == true || this.empcode != "")
      );
    },
  },
  methods: {
    // 选择职级
    showPopup(name) {
      this.showWorkType = true;
      this.popType = name;
    },
    // 选择岗位，跳到岗位树
    selGW() {
      this.$router.push("/selStation");
    },
    onConfirm(value, index) {
      this.showWorkType = false;
      if (this.popType == "职级") {
        this.workLeve = value;
      }
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.showWorkType = false;
    },
    // 取消选择日期
    cancelSel() {
      this.showDate = false;
    },
    selTime(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let day = val.getDate();
      day = day < 10 ? "0" + day : day;
      this.entrydate = year + "-" + month + "-" + day;
      this.showDate = false;
    },
    // 选择入职日期
    selEntryDate() {
      this.showDate = true;
    },
    onClickLeft() {
      if(this.ischange_empcode){
        if(!this.empcode){
          Toast("请输入工号");
          return false;
        }
      }else{
        this.empcode = "";
      }
      if (this.$route.query.returnPath == "toInduction") {
        this.$router.push({
          path:"/inductionInfo",
          query:{
            autoid:this.$route.query.autoid,
            stationid:this.$route.query.stationid,
            fromType:"待入职"
          }
        });
      } else{
        this.$router.push("/toInduction");
      }
    },
    onClickRight() {},
    // 确认入职
    confirm() {
      confirmEntry({
        autoid: this.autoid,
        entrydate: this.entrydate,
        ischange_empcode: this.ischange_empcode == true ? 1 : 0,
        empcode: this.empcode,
        isautocode: this.isautocode == true ? 1 : 0,
        username: this.userInfo.username
      }).then((res) => {
        if(res.data[0].info) {
          Toast.fail("失败！" + res.data[0].info);
        }else{
          Toast.success("入职成功！");
        }
      });
    },
  },
  mounted() {
    this.gw = this.$route.query.gw;
  },
};
</script>

<style lang="less" scoped>
.confirmEntry {
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    margin-bottom: 24px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/ .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-size: 32px;
      }
    }
  }
  /deep/.van-field {
    .van-cell__value {
      .van-field__body {
        .van-field__control {
          text-align: right;
        }
      }
    }
  }
  .probation {
    margin-bottom: 24px;
  }
  .name {
    margin-bottom: 24px;
    /deep/.van-cell__value {
      .van-field__body {
        input {
          text-align: right;
        }
      }
    }
  }
  .syq {
    margin-bottom: 24px;
  }
  .van-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 100px);
    height: 102px;
    // color:#2B8DF0;
    font-size: 40px;
  }
}
</style>
